import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { Observable } from 'rxjs';
import { axiosInstance } from './axiosInstance';
import { setBillingProducts, setCompanyData } from '../reduxStore/actions/flowActions';
var qsSimple = require('qs');

export const getStripePlans = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.payments.getStripeProductsAndPrices(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getSubscriptionPlans = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.payments.getProductsAndPrices(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					store.dispatch(setBillingProducts(response.data.data));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getInvoices = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.payments.getCompanyInvoices(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getPurchases = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.payments.getCompanyPurchases(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getCurrentSubscription = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.payments.getCurrentSubscription(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const subscriptionPayment = (quantity, subscriber_rate, pmethod, one_time = 0) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('pmethod', pmethod);
	data.append('quantity', +quantity);
	data.append('subscriber_rate', subscriber_rate);
	data.append('one_time', one_time);

	const defaultConfig = { ...URLS.payments.subscriptionPayment(last_access_token) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const servicesPayment = (service_name, service_cost, duration) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		service_name: service_name,
		service_cost: +service_cost,
	};

	if (duration != null) {
		data.duration = +duration;
	}

	const defaultConfig = { ...URLS.payments.servicesPayment(last_access_token) };
	const config = {
		...defaultConfig,
		data: duration == null ? qsSimple.stringify(data) : qsSimple.stringify({ ...data, duration: duration }),
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const unsubscribe = reasons => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		immediately: 1,
		reasons: reasons,
	};

	const defaultConfig = { ...URLS.payments.unsubscribe(last_access_token) };
	const config = {
		...defaultConfig,
		data: qsSimple.stringify(data),
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const startTrial = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const { companyData } = store.getState().flowReducer;

	const config = {
		...URLS.payments.startTrial(last_access_token),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					store.dispatch(setCompanyData({ ...companyData, status: 'on_trial' }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const setActive = payment => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		pmethod: 'card',
		paymentId: payment.id,
		service_cost: +payment?.total,
		service_name: payment.product,
		quantity: +payment.quantity,
		ends_at: payment.ends_at,
	};

	const defaultConfig = { ...URLS.payments.active(last_access_token) };
	const config = {
		...defaultConfig,
		data: qsSimple.stringify(data),
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					store.dispatch(setCompanyData(response?.data?.data));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const purchase = (payment, payment_to) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		pmethod: 'card',
		user_id: payment.user_id,
		paymentId: payment.id,
		service_cost: +payment?.total,
		service_name: payment.product,
		notes: payment.notes,
		quantity: 1,
	};

	const defaultConfig = payment_to ? { ...URLS.payments.payment(last_access_token, payment_to) } : { ...URLS.payments.purchase(last_access_token) };
	const config = {
		...defaultConfig,
		data: qsSimple.stringify(data),
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					//store.dispatch(setCompanyData(response?.data?.data));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const attachIntent = (paymentIntentId, paymentMethodId, clientKey) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		data: {
			attributes: {
				payment_method: paymentMethodId,
				client_key: clientKey,
				return_url: 'http://www.google.com',
			},
		},
	};
	const defaultConfig = { ...URLS.payments.attachIntent(paymentIntentId) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				console.log('error', error);
				subscriber.error(error);
			})
	);
};

export const paymentMethod = card => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		data: {
			attributes: {
				type: 'card',
				details: {
					card_number: card.card_number,
					exp_month: card.exp_month,
					exp_year: card.exp_year,
					cvc: card.cvc,
				},
				billing: {
					address: card.address,
					name: card.name,
					phone: card.phone,
					email: card.email,
				},
				payment_method_option: {
					// card: {
					// 	installments: {
					// 		plan: {
					// 			issuer_id: "string",
					// 			tenure: 0
					// 		}
					// 	}
					// }
				},
				metadata: {
					key1: 'value',
					key2: 'value',
				},
			},
		},
	};

	const defaultConfig = { ...URLS.payments.paymentMethod() };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				console.log('error', error);
				subscriber.error(error);
			})
	);
};

export const createIntent = (amount = 0, pmethod = '"card"', currency = '"PHP"') => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		amount: +amount,
		pmethod: pmethod,
		currency: currency,
	};
	const defaultConfig = { ...URLS.payments.createIntent(last_access_token) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const checkout = (line_items = [], billing = {}, success_url = 'https://www.flowhero.app', reference_number = '"---"', pmethod = '"card","gcash","dob","paymaya","dob_ubp"') => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const data = {
		billing: billing,
		payment_method_types: pmethod,
		pmethod: pmethod,
		line_items: line_items,
		reference_number: reference_number,
		success_url: success_url,
	};
	const defaultConfig = { ...URLS.payments.checkout(last_access_token) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
