import React, { useEffect, useState } from 'react';
import cls from './MenuBar.module.scss';
import { CartMenuEntry, DashboardMenuEntry, HelloNameMenuEntry, MainMenuEntry, MobileMainMenuEntry, ModulesMenuEntry, ProcessMenuEntry, SingleNavigateModule } from '../../HeaderMenuButtons/HeaderMenuButtons';
import { LogoutModal, ModalR, PermissionWrapper, RenderTitle, po } from '../../../common';
import { useSelector } from 'react-redux';
import { store } from '../../../../reduxStore/store';
import { setCurrentTask, setProcessModule } from '../../../../reduxStore/actions/flowActions';
import { isMobile } from 'react-device-detect';
import { FLOWHERO } from '../../../../shared/utility';
import ModulesMenu from '../../../SideMenuEntries/ModulesMenu';

const MenuBar = () => {
	const [userLogout, setUserLogout] = useState(false);

	const { allModules, cart, companyData, showSideMenu } = useSelector(store => store.flowReducer);
	const { user } = useSelector(store => store.sessionReducer);
	const [showAbout, setShowAbout] = useState(false);


	useEffect(() => {
		if (allModules.length == 1) {
			store.dispatch(setCurrentTask(undefined));
			store.dispatch(
				setProcessModule({
					...allModules[0].procedure,
					stepIndex: 0,
					module_id: allModules[0].id,
					module: allModules[0],
				})
			);
		}
	}, [allModules]);

	const AboutR = () => {
		return (
			<div style={{ margin: 'auto', color: 'var(--app-color-gray-dark)', padding: '0 0 40px 0' }}>
				{FLOWHERO('28px', 'var(--app-color-gray-dark)', 'bold')}
				<p>{`© 2020-2025 FlowHeroApps Solutions. All rights reserved.`}</p>
			</div>
		);
	};

	return (
		<div className={cls.menuBar} style={{ backgroundColor: companyData?.company_standards?.theme ?? 'var(--app-color-gray-dark)', color: (companyData?.company_standards?.theme === 'white' || companyData?.company_standards?.theme === '#F6F4EC') ? 'var(--app-color-gray-dark)' : 'var(--app-color-white-lightest)' }}>
			<nav className={cls.nav} >
				<div style={{ display: 'flex', marginLeft: '2%' }}>

					{companyData?.logo ?
						<img alt="lg" src={companyData?.logo} className={isMobile ? cls.logoUrlImgMobile : cls.logoUrlImgMobile} /> :
						<div style={{ padding: '20px' }}>
							{FLOWHERO(isMobile ? '18px' : '28px', 'white', 'bold')}
							<div style={{ fontSize: isMobile ? '10px':'14px', fontWeight: 'normal' }}>
								{companyData?.display_name}
							</div>
						</div>
					}

					<div className={cls.ul}>
						<PermissionWrapper permissions={[po.blueprints.process.create]}>
							{!isMobile && <DashboardMenuEntry />}
						</PermissionWrapper>
						<PermissionWrapper permissions={[po.blueprints.process.create]}>
							{(companyData.status === 'active' || companyData.status === 'on_trial' || companyData.status === 'free'|| companyData.status === 'superadmin')
								&& !isMobile &&
								<ProcessMenuEntry />}
						</PermissionWrapper>

						{allModules?.length > 0 &&
							!isMobile &&
							(allModules?.length < 3 ? (
								<ModulesMenu inner={true} />
							) : (
								isMobile && <ModulesMenuEntry />
							))
						}

					</div>
				</div>

				<ul className={cls.ul}>
					{cart?.items?.length > 0 ? <CartMenuEntry orders={cart} companyData={companyData} /> : !isMobile && <HelloNameMenuEntry message={`Hello, ${user?.first_name}`} />}
					<MainMenuEntry userLogout={userLogout} setUserLogout={setUserLogout} onAboutClick={() => setShowAbout(true)}
						showBilling={user.is_admin || allModules.filter(mod => mod.options?.credits == true).length > 0}
						logo={user?.photo ? <div className={cls.avatarContentContainer}><img alt="lg" src={user?.photo} className={cls.avatarUrlImg} /></div> : undefined} />
				</ul>

				{userLogout && <LogoutModal openModal={userLogout} setOpenModal={setUserLogout} slug={companyData?.slug} />}
				{showAbout && (
					<ModalR blured={true} hasCloseIcon scrollable={true} isOpen={showAbout} onClose={() => setShowAbout(false)} shadowedContainerStyle={{ minWidth: '400px', width: '30%', height: 'auto' }}>
						<AboutR />
					</ModalR>
				)}
			</nav>
			{showSideMenu && !isMobile && allModules?.length > 2 && <ModulesMenu />}
		</div>
	);
};

export default MenuBar;

export const TopMenu = () => {
	const [userLogout, setUserLogout] = useState(false);
	const { allModules, companyData } = useSelector(store => store.flowReducer);

	useEffect(() => {
		if (allModules.length == 1) {
			store.dispatch(setCurrentTask(undefined));
			store.dispatch(
				setProcessModule({
					...allModules[0].procedure,
					stepIndex: 0,
					module_id: allModules[0].id,
					module: allModules[0],
				})
			);
		}
	}, [allModules]);

	return (
		<nav className={cls.nav2}>
			<ul className={cls.ul}>
				<li className={cls.li}>
					<a className={cls.a} href="/dashboard">
						Dashboard
					</a>
				</li>
				{allModules?.length > 0 &&
					(allModules?.length === 1 ? (
						<li className={cls.li}>
							<a className={cls.a} href="/begin-module">
								{allModules[0]?.name}
							</a>
						</li>
					) : (
						<ModulesMenuEntry />
					))}
				<PermissionWrapper permissions={[po.blueprints.process.create]}>
					<ProcessMenuEntry />
				</PermissionWrapper>

				<MainMenuEntry userLogout={userLogout} setUserLogout={setUserLogout} />
			</ul>
			{userLogout && <LogoutModal openModal={userLogout} setOpenModal={setUserLogout} slug={companyData?.slug} />}
		</nav>
	);
};

export const MobileMenuBar = props => {
	const [userLogout, setUserLogout] = useState(false);
	const { onTransactionsClick } = props;
	const { allModules, cart, companyData } = useSelector(store => store.flowReducer);
	const { user } = useSelector(store => store.sessionReducer);
	const [showAbout, setShowAbout] = useState(false);

	useEffect(() => {
		if (allModules.length == 1) {
			store.dispatch(setCurrentTask(undefined));
			store.dispatch(
				setProcessModule({
					...allModules[0].procedure,
					stepIndex: 0,
					module_id: allModules[0].id,
					module: allModules[0],
				})
			);
		}
	}, [allModules]);

	const AboutR = () => {
		return (
			<div style={{ alignSelf: 'center', color: 'var(--app-color-gray-dark)' }}>
				{FLOWHERO('28px', 'var(--app-color-gray-dark)', 'bold')}
				<p>{`© 2020-2025 FlowHeroApps Solutions. All rights reserved.`}</p>
			</div>
		);
	};
	return (
		<nav className={cls.nav} style={{ display: '', backgroundColor: 'white' }}>
			<ul className={cls.ul}>
				<MobileMainMenuEntry userLogout={userLogout} setUserLogout={setUserLogout} onAboutClick={() => setShowAbout(true)} onTransactionsClick={onTransactionsClick} />
			</ul>

			{userLogout && <LogoutModal openModal={userLogout} setOpenModal={setUserLogout} slug={companyData?.slug} />}
			{showAbout && (
				<ModalR blured={true} hasCloseIcon scrollable={true} isOpen={showAbout} onClose={() => setShowAbout(false)} shadowedContainerStyle={{ minWidth: '400px', width: '30%', height: 'auto' }}>
					<AboutR />
				</ModalR>
			)}
		</nav>
	);
};
