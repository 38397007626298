import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartThreeState {
  series: number[];
}



export type ChartThreeProps = {
  series: any;
  labels: any;
  title: any;
}

const ChartThree = (props: ChartThreeProps) => {
  const { series, labels, title = 'Total' } = props
  const [state, setState] = useState<ChartThreeState>({
    series: series,
  });

  const options: ApexOptions = {
    chart: {
      fontFamily: 'var(--app-text-main-font)',
      type: 'donut',
    },
    colors: ['#3C50E0', '#0FADCF', '#6577F3', '#8FD0EF'],
    labels: labels,
    legend: {
      show: true,
      position: 'bottom',
    },

    plotOptions: {
      pie: {
        donut: {
          //size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'var(--app-text-main-font)',
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val
              }
            },
            value: {
              show: true,
              fontSize: '14px',
              fontFamily: 'var(--app-text-main-font)',
              fontWeight: 'normal',
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val
              }
            },
            total: {
              show: true,
              showAlways: true,
              label: title,
              fontSize: '22px',
              fontFamily: 'var(--app-text-main-font)',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              }
            }
          }
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    responsive: [
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 380,
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
      series: series,
    }));
  };
  handleReset;

  return (
    <div className="mb-2">
      <div id="chartThree" className="mx-auto flex justify-center">
        <ReactApexChart
          options={options}
          series={state.series}
          type="donut"
        />
      </div>
    </div>
  );
};

export default ChartThree;
