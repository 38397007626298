import { Observable } from 'rxjs';
import queryString from 'query-string';
import { setAuthenticatedUser } from '../reduxStore/actions/authActions';
import { deleteUser, setUnassignedUsersArray, setUsersArray, setLastUpdate, setFilters, setMessageBar } from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import { sessionService } from 'redux-react-session';
import moment, { now } from 'moment';

export const setUserOnAPosition = position => {
	const {
		sessionSettings: { last_access_token },
		authenticatedUser: { id },
	} = store.getState().authReducer;

	const data = new FormData();
	data.append('position_id', `${position.id}`);
	const config = {
		...URLS.users.addUserToAPosition(last_access_token, id, data),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					store.dispatch(setAuthenticatedUser({ position: position }));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getAllUsers = (forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { lastUpdates, companyUsersArray, axiosAbort } = store.getState().flowReducer;

	const config = {
		...URLS.users.getAllUsers(last_access_token),
		handlerEnabled: true,
		signal: axiosAbort.signal
	};

	//console.log('moment diff users', moment(now()).diff(moment(lastUpdates.users), 'minutes'), companyUsersArray.length);

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.users), 'minutes') > 10 || companyUsersArray.length === 0) {
			axiosInstance(config)
				.then(response => {
					if (response?.status == 200 && response?.data && response?.data?.data) {
						const users = [
							...(response?.data?.data?.map(i => ({
								id: i.id,
								first_name: i.first_name,
								last_name: i.last_name,
								email: i.email,
								position: `${i.positions?.map(u => u.name).join(', ')}`,
								is_admin: i.is_admin,
								photo: i.photo,
								timezone: i.timezone,
								positions: i.positions,
								validity: i.validity,
							})) ?? []),
						];

						store.dispatch(setUsersArray(Array.from(response?.data?.data)));
						store.dispatch(setLastUpdate({ ...lastUpdates, users: now() }));
						subscriber.next(users);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};

export const createUser = userData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('email', userData.email);
	data.append('username', userData?.username ?? '');
	data.append('first_name', userData.first_name);
	data.append('last_name', userData.last_name);
	data.append('timezone', userData.timezone);
	if (userData.position_id) {
		data.append('position_id', userData.position_id);
	}
	const config = {
		...URLS.users.createUser(last_access_token),
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.data && response?.data?.data) {
					const user = response?.data?.data;
					subscriber.next(user);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const checkUserEmail = userData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.users.checkUserEmail(last_access_token, userData.email),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getUsersByPositions = positions => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const { companyUsersArray } = store.getState().flowReducer;
	const config = {
		data: { positions: positions },
		...URLS.users.getUsersInSeveralPositions(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {

					const new_users = Object.values(response?.data?.data);

					store.dispatch(setUsersArray(companyUsersArray.filter(user => !new_users.map(u => {
						return u.id;
					}).includes(user.id)).concat(new_users)));

					subscriber.next([
						...(Object.values(response?.data?.data)?.map(i => ({
							label: i.first_name + ' ' + i.last_name,
							value: i.id,
							user: i
						})) ?? []),
					]);
					subscriber.complete()
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getUsersByPositionsV2 = (positions, per_page = 100, page = 1) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		data: { positions: positions },
		...URLS.users.getUsersInSeveralPositions(last_access_token, per_page, page),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					//store.dispatch(setUsersArray(Object.values(response?.data?.data)));
					subscriber.next({
						current_page: response?.data?.current_page,
						last_page: response?.data?.last_page,
						per_page: +response?.data?.per_page,
						total: response?.data?.total,
						data: [
							...(Object.values(response?.data?.data)?.map(i => ({
								label: i.first_name + ' ' + i.last_name,
								value: i.id,
								user: i
							})) ?? []),
						]
					});
					subscriber.complete()
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getUsersByPositionsWithFilter = (positions, filter, value) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const { companyUsersArray } = store.getState().flowReducer;
	const config = {
		data: {
			positions: positions,
			filter: filter,
			value: value
		},
		...URLS.users.getUsersInSeveralPositions(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					//store.dispatch(setUsersArray(Array.from(response?.data?.data)));
					subscriber.next([
						...(response?.data?.data.map(i => ({
							label: i?.first_name + ' ' + i?.last_name,
							value: i?.id,
							user: i
						})) ?? []),
					]);
					subscriber.next(response?.data?.data?.map(i => ({
							label: i?.first_name + ' ' + i?.last_name,
							value: i?.id,
							user: i
						})),
					);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getUnassignedUsers = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.users.getUnassignedUser(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					const user = [
						...(response?.data?.data?.map(i => ({
							id: i.id,
							firstName: i.first_name,
							lastName: i.last_name,
							email: i.email,
							timezone: i.timezone,
							departments: [i.departments],
							positions: [i.positions],
						})) ?? []),
					];
					store.dispatch(setUnassignedUsersArray(user));
					subscriber.next(user);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const removeUserbyId = userId => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	const defaultConfig = { ...URLS.users.deleteUserbyId(last_access_token, userId) };
	const config = {
		...defaultConfig,
		headers: { ...defaultConfig.headers },
		data: data,
		handlerEnabled: true,
		refreshOrgChart: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					store.dispatch(deleteUser(userId));
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const getAuthenticatedUser = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.users.getAuthenticatedUser(last_access_token),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const changeUsersPassword = (oldPassword, newPassword, newPasswordConfirmation, userId) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = new FormData();
	data.append('new_password', newPassword);
	data.append('new_password_confirmation', newPasswordConfirmation);
	data.append('old_password', oldPassword);

	const config = {
		...URLS.users.changePassword(last_access_token, userId),
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const updateUserById = (userId, first_name, last_name, phone = '', address = '', city = '', postal = '', timezone, validity) => {
	const {
		sessionSettings: { last_access_token, authenticatedUser },
	} = store.getState().authReducer;
	const query = validity
		? queryString.stringify(
			{ first_name: first_name, last_name: last_name, timezone: timezone, validity: validity, phone: phone },
			{
				skipNull: true,
				skipEmptyString: true,
			}
		)
		: queryString.stringify(
			{ first_name: first_name, last_name: last_name, timezone: timezone, phone: phone, address: address, city: city, postal: postal },
			{
				skipNull: true,
				skipEmptyString: true,
			}
		);
	const defaultConfig = { ...URLS.users.updateUserById(last_access_token, userId) };
	const config = {
		...defaultConfig,
		data: query,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data?.data) {
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated user` }));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};

export const updateUserOptionsById = (user, options) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = queryString.stringify(
		{ options: JSON.stringify(options) },
		{
			skipNull: true,
			skipEmptyString: true,
		}
	);

	const defaultConfig = { ...URLS.users.updateUserById(last_access_token, user.id) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data?.data) {
					store.dispatch(setMessageBar({ show: true, message: `You have successfully updated user` }));
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const updateUserProfilePicture = (userId, avatarFile) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('photo', avatarFile);
	const config = {
		...URLS.users.updateUserProfilePicture(last_access_token, userId),
		data: data,
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const rateUserById = (user, ratings) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = queryString.stringify(
		{ ratings: ratings.ratings, comments: ratings.comments, component_id: ratings.component_id },
		{
			skipNull: true,
			skipEmptyString: true,
		}
	);

	const defaultConfig = { ...URLS.users.rateUserById(last_access_token, user.id) };
	const config = {
		...defaultConfig,
		data: data,
		handlerEnabled: true,
		forwardAllErrors: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data?.data) {
					subscriber.next(response.data.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getUsersFilter = () => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.users.getUsersFilter(last_access_token),
		handlerEnabled: true,
	};

	const { filters } = store.getState().flowReducer;

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					store.dispatch(setFilters({ ...filters, users: response?.data?.data }));
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getFilteredUsers = sentData => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	var data = JSON.stringify({ filters: [...sentData] });
	const config = {
		...URLS.users.getFilteredUsers(last_access_token),
		data: data,
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status == 200 && response?.data && response?.data?.data) {
					subscriber.next(response?.data?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
