import ReactApexChart from "react-apexcharts";
import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";
import ChartThree from "./ChartThree";

const CustomCharts = props => {
    const { orientation, series, labels, config, title, type } = props;

    return <div>

        {orientation === 'Line'  && <ChartOne
            series={series}
        />}

        {orientation === 'Column'  && <ChartTwo
            series={series}
        />}

        {orientation === 'Donut' && <ChartThree
            title={title}
            series={series}
            labels={labels}
            config={config}
            type={type}
        />}

        {orientation === 'Custom'  &&
            <div className="mb-2">
                <div id="reactApexChart" className="mx-auto flex justify-center">
                    <ReactApexChart
                        options={config}
                        series={series}
                        type={type}
                    />
                </div>
            </div>}
    </div>
}

export default CustomCharts;