import {
	ADD_NEW_STATUS,
	ADD_NEW_TASK,
	DELETE_TASK,
	EDIT_INVITE_COLLEGUES_LIST,
	SET_COMPANY_DATA,
	SET_COMPANY_DEPARTMENTS,
	SET_COMPANY_ORG_CHART_DATA,
	SET_COMPANY_POSSITIONS_ARRAY,
	SET_COMPANY_PROCESSES,
	SET_COMPLETED_TASKS,
	SET_DUPLICATE_POSITION_INFO,
	SET_INVITE_COLLEGUES_LIST,
	SET_ONBOARDING_COMPLETED,
	SET_POSITION_INFO,
	SET_SELECTED_PROCESS,
	SET_SELECTED_PROCESS_STEP,
	RESET_SELECTED_PROCESS_STEP,
	SET_SELECTED_PROCESS_STEP_OPTIONS,
	SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
	REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
	SET_SERVER_FEATURE_NOT_READY_YET,
	SET_STATUTES,
	SET_TASKS,
	SET_UNASSIGNED_USSERS_ARRAY,
	SET_USERS_ARRAY,
	SET_NEW_COMPONENT_BLUEPRINT,
	SET_SELECTED_COMPONENT_BLUEPRINT,
	DELETE_USER,
	DELETE_COMPONENT_BLUEPRINT,
	SET_BEGUN_PROCESS_TASK,
	SET_BLUEPRINT_COMPONENTS,
	CHANGE_TASK_PROCESS,
	RESET_FLOW_REDUCER,
	SET_BEGUN_PROCESS_MODULE,
	SET_ALL_MODULES,
	SET_COMPONENTS,
	SET_BLUEPRINT_COMPONENT_TYPES,
	ADD_COMPONENT,
	DELETE_COMPONENT,
	UPDATE_COMPONENT,
	SET_LAST_UPDATE,
	SET_FILTERS,
	SET_CURRENT_TASK,
	SET_PRODUCTS,
	ADD_PRODUCT,
	DELETE_PRODUCT,
	SET_TRANSACTIONS,
	ADD_TRANSACTION,
	DELETE_TRANSACTION,
	EDIT_TASK,
	ADD_FAVORITE,
	SET_FAVORITES,
	DELETE_FAVORITE,
	CHANGE_MODULE_PROCESS,
	UPDATE_COMPANY_PROCESSES,
	SET_MESSAGE_BAR,
	SET_SHOW_MENU,
	SET_LAST_COMPONENT_UPDATE,
	SET_CART,
	SET_SHOW_SIDE_MENU,
	SET_BILLABLE,
	SET_BILLING_PRODUCTS,
	SET_PAYABLE,
	SET_CURRENT_LOCATION,
	RESTART_AXIOS_ABORT
} from './types';

export const setInviteColleguesList = arrayColleguesList => dispatch => dispatch({ type: SET_INVITE_COLLEGUES_LIST, payload: arrayColleguesList });
export const addInviteColleguesList = objectColleguesList => dispatch => dispatch({ type: EDIT_INVITE_COLLEGUES_LIST, payload: objectColleguesList });
export const setCompanyData = companyDataObj => dispatch => dispatch({ type: SET_COMPANY_DATA, payload: companyDataObj });
export const setCompanyDepartments = companyDepartmentsArray => dispatch => dispatch({ type: SET_COMPANY_DEPARTMENTS, payload: companyDepartmentsArray });
export const setCompanyPositionsArray = posiArray => dispatch => dispatch({ type: SET_COMPANY_POSSITIONS_ARRAY, payload: posiArray });
export const setPositionInfo = positionInfo => dispatch => dispatch({ type: SET_POSITION_INFO, payload: positionInfo });
export const setDuplicatePositionInfo = duplicatePositionInfo => dispatch => dispatch({ type: SET_DUPLICATE_POSITION_INFO, payload: duplicatePositionInfo });

export const setCompanyOrgChartData = orgChartData => dispatch => dispatch({ type: SET_COMPANY_ORG_CHART_DATA, payload: orgChartData });
export const setOnBoardingComplete = onBoardingCompleted => dispatch => dispatch({ type: SET_ONBOARDING_COMPLETED, payload: onBoardingCompleted });

export const setCompanyProcesses = (companyProcessesArray, page) => dispatch => dispatch({ type: SET_COMPANY_PROCESSES, payload: companyProcessesArray, page: page });
export const updateCompanyProcesses = process => dispatch => dispatch({ type: UPDATE_COMPANY_PROCESSES, payload: process });
export const setComapanyPinnedProcesses = companyPinnedProcessesArray => dispatch => dispatch({ type: SET_COMPANY_PROCESSES, payload: companyPinnedProcessesArray });
export const setSelectedProcess = selectedProcess => dispatch =>
	dispatch({
		type: SET_SELECTED_PROCESS,
		payload: selectedProcess,
	});
export const setSelectedProStep = selectedProStep => (dispatch, _getState) =>
	dispatch({
		type: SET_SELECTED_PROCESS_STEP,
		payload: selectedProStep,
	});
export const setSelectedProStepOptions = selectedProStepOption => (dispatch, _getState) =>
	dispatch({
		type: SET_SELECTED_PROCESS_STEP_OPTIONS,
		payload: selectedProStepOption,
	});
export const setSelectedProStepCompBP = selectedProStepCompBP => (dispatch, _getState) =>
	dispatch({
		type: SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
		payload: selectedProStepCompBP,
	});
export const removeSelectedProStepCompBP = removeSelectedProStepCompBP_id => (dispatch, _getState) =>
	dispatch({
		type: REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
		payload: removeSelectedProStepCompBP_id,
	});

export const resetSelectedProStep = () => dispatch =>
	dispatch({
		type: RESET_SELECTED_PROCESS_STEP,
	});

export const setProcessTask = (task, isResumed) => dispatch => dispatch({ type: SET_BEGUN_PROCESS_TASK, payload: task, is_resumed: isResumed });
export const handleChangeTaskProcess = process => dispatch => dispatch({ type: CHANGE_TASK_PROCESS, payload: process });
export const setAllComponents =
	(components, page = 1) =>
	dispatch =>
		dispatch({ type: SET_COMPONENTS, payload: components, page: page });
export const setComponentTypes = types => dispatch => dispatch({ type: SET_BLUEPRINT_COMPONENT_TYPES, payload: types });
export const addComponent = component => dispatch => dispatch({ type: ADD_COMPONENT, payload: component });
export const removeComponent = component => dispatch => dispatch({ type: DELETE_COMPONENT, payload: component });
export const editComponent = component => dispatch => dispatch({ type: UPDATE_COMPONENT, payload: component });
export const setAllModules =
	(modules, page = 1) =>
	dispatch =>
		dispatch({ type: SET_ALL_MODULES, payload: modules, page: page });
export const setProcessModule = process => dispatch => dispatch({ type: SET_BEGUN_PROCESS_MODULE, payload: process });
export const changeProcessModule = value => dispatch => dispatch({ type: CHANGE_MODULE_PROCESS, payload: value });
export const handleChangeProcessModule = process => dispatch => dispatch({ type: CHANGE_TASK_PROCESS, payload: process });

export const setTasks = tasksArray => dispatch => dispatch({ type: SET_TASKS, payload: tasksArray });
export const taskDelete = taskToDelete => dispatch => {
	dispatch({
		type: DELETE_TASK,
		payload: taskToDelete,
	});
};
export const addNewTask = taskData => dispatch => dispatch({ type: ADD_NEW_TASK, payload: taskData });
export const editTask = taskData => dispatch => dispatch({ type: EDIT_TASK, payload: taskData });
export const addNewStatus = statusData => dispatch =>
	dispatch({
		type: ADD_NEW_STATUS,
		payload: statusData,
	});
export const setCompletedTasks = (completedTasksArray, page) => dispatch =>
	dispatch({
		type: SET_COMPLETED_TASKS,
		payload: completedTasksArray,
		page: page,
	});

export const setBlueprintComponents = (compArray, page) => dispatch =>
	dispatch({
		type: SET_BLUEPRINT_COMPONENTS,
		payload: compArray,
		page: page,
	});

export const setNewComponentBlueprint = newComponentBlueprint => dispatch =>
	dispatch({
		type: SET_NEW_COMPONENT_BLUEPRINT,
		payload: newComponentBlueprint,
	});

export const componentBlueprintDelete = componentToDelete => dispatch => {
	dispatch({
		type: DELETE_COMPONENT_BLUEPRINT,
		payload: componentToDelete,
	});
};

export const setSelectedComponentBlueprint = selectedComponentBlueprint => dispatch =>
	dispatch({
		type: SET_SELECTED_COMPONENT_BLUEPRINT,
		payload: selectedComponentBlueprint,
	});

export const setStatutes = statusArray => dispatch =>
	dispatch({
		type: SET_STATUTES,
		payload: statusArray,
	});

export const setUsersArray = usersArray => dispatch =>
	dispatch({
		type: SET_USERS_ARRAY,
		payload: usersArray,
	});

export const deleteUser = user => dispatch =>
	dispatch({
		type: DELETE_USER,
		payload: user,
	});

export const setUnassignedUsersArray = unassignedUssersArray => dispatch =>
	dispatch({
		type: SET_UNASSIGNED_USSERS_ARRAY,
		payload: unassignedUssersArray,
	});
export const setServerFeatureNotReadyYet = value => dispatch =>
	dispatch({
		type: SET_SERVER_FEATURE_NOT_READY_YET,
		payload: value,
	});
export const setLastUpdate = value => dispatch =>
	dispatch({
		type: SET_LAST_UPDATE,
		payload: value,
	});
export const setComponentUpdate = value => dispatch =>
	dispatch({
		type: SET_LAST_COMPONENT_UPDATE,
		payload: value,
	});
export const resetFlowReducer = () => (dispatch, _getState) => dispatch({ type: RESET_FLOW_REDUCER });
export const setFilters = value => dispatch =>
	dispatch({
		type: SET_FILTERS,
		payload: value,
	});
export const setCurrentTask = value => dispatch =>
	dispatch({
		type: SET_CURRENT_TASK,
		payload: value,
	});
export const addProduct = product => dispatch => dispatch({ type: ADD_PRODUCT, payload: product });
export const setProducts = products => dispatch => dispatch({ type: SET_PRODUCTS, payload: products });
export const deleteProduct = product => dispatch => dispatch({ type: DELETE_PRODUCT, payload: product });

export const addTransaction = transaction => dispatch => dispatch({ type: ADD_TRANSACTION, payload: transaction });
export const setTransactions = transactions => dispatch => dispatch({ type: SET_TRANSACTIONS, payload: transactions });
export const deleteTransaction = transaction => dispatch => dispatch({ type: DELETE_TRANSACTION, payload: transaction });

export const addFavorite = favorite => dispatch => dispatch({ type: ADD_FAVORITE, payload: favorite });
export const setFavorites = favorites => dispatch => dispatch({ type: SET_FAVORITES, payload: favorites });
export const deleteFavorite = favorite => dispatch => dispatch({ type: DELETE_FAVORITE, payload: favorite });

export const setMessageBar = showMessage => dispatch => dispatch({ type: SET_MESSAGE_BAR, payload: showMessage });
export const setShowMenu = showMenu => dispatch => dispatch({ type: SET_SHOW_MENU, payload: showMenu });
export const setShowSideMenu = showMenu => dispatch => dispatch({ type: SET_SHOW_SIDE_MENU, payload: showMenu });
export const setCart = data => dispatch => dispatch({ type: SET_CART, payload: data });
export const setBillable = data => dispatch => dispatch({ type: SET_BILLABLE, payload: data });
export const setBillingProducts = data => dispatch => dispatch({ type: SET_BILLING_PRODUCTS, payload: data });
export const setPayable = data => dispatch => dispatch({ type: SET_PAYABLE, payload: data });
export const setCurrentLocation = location => dispatch => dispatch({ type: SET_CURRENT_LOCATION, payload: location });
export const restartAxiosAbort = () => dispatch => dispatch({ type: RESTART_AXIOS_ABORT, payload: {} });
