import { connect } from 'react-redux';
import cls from './Modules.module.scss';
import { useNavigate } from 'react-router-dom';
import { store } from '../../reduxStore/store';
import { setProcessModule, setCurrentTask, setShowMenu, setAllComponents } from '../../reduxStore/actions/flowActions';

import * as R from 'ramda';
import { isMobile } from 'react-device-detect';
import { app_max, APP_NAME, APPEND_TO_DATA, CLEAR_TO_SET_DATA, page_default } from '../../shared/utility';
import { take } from 'rxjs';
import { getAlComponentsByModuleId } from '../../services/module.service';
import { now } from 'moment';
import { Button } from '../common';
import { useState } from 'react';
import { getAllCompanyPositionsAsArray } from '../../services/company.service';
import { processTaskAction, useProcessTaskContext } from '../../context/processTask.context';
import { getUsersByPositions } from '../../services/user.service';
import UserCard from '../common/UserCard/UserCard';

function ModulesMenu(props) {
	const { allModules, user, lastUpdates, components, blueprints, companyPositionsArray, companyProcesses, inner = false, companyData } = props;
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState();
	const [taskProcessContext, setTaskProcessContext] = useProcessTaskContext();
	const [fullPage, setFullPage] = useState(false);

	const navigate = useNavigate();

	const goToTransaction = (process_id, component, status = undefined) => {
		if (!component) {

			navigate('/begin-module');
			return;
		}
		let component_array = [];
		let step_no = 0;

		const process = companyProcesses.find(proc => proc.id === process_id);

		process?.steps?.length > 0 && process?.steps?.filter(step => step?.component_blueprints?.filter(cb => cb.selected_fields.filter(field => (field.value = undefined))));
		R.uniqWith(R.eqProps('group'))(component.component_fields).map(f => {
			component_array = [...component_array, { id: f.component_blueprint_id, temp_id: f.id, selected_fields: R.filter(R.propEq('group', f.group))(component.component_fields) }];
		});

		process.steps.filter(step => {
			step.component_blueprints.filter(cb => {
				cb.selected_fields.filter(sf => {
					const field_found = component.component_fields.find(cf => cf.field_name === sf.field_name);
					if (sf.data_type_name === 'Status' && status !== undefined) {
						step_no = +step.rank - 1;
					}

					if (field_found != undefined) {
						sf.value = field_found.value;
					} else if (sf.data_type_name === 'Record') {
						const label_found = component.component_fields.find(cf => cf.field_name === sf.options.name);
						if (label_found != undefined) {
							sf.value = label_found.value;
						}
					}
				});
			});
		});

		setTaskProcessContext({
			action: processTaskAction.setComponents,
			value: component_array,
		});
		setTaskProcessContext({
			action: processTaskAction.setModuleItems,
			value: component.component_items,
		});


		store.dispatch(
			setProcessModule({
				...process,
				steps: process.steps,
				stepIndex: step_no,
				module_id: component.module_id,
				module: allModules.find(mod => mod.id === component.module_id),
				isUpdating: true,
				selectedComponent: component,
			})
		);
		store.dispatch(setShowMenu(false));
		navigate('/begin-module');
	};

	const gotoModule = (item) => {
		document.title = `${item.name} | ${APP_NAME}`;
		store.dispatch(setCurrentTask(undefined));
		store.dispatch(
			setProcessModule({
				...item.procedure,
				stepIndex: 0,
				module_id: item.id,
				module: item,
			})
		);

		if (companyPositionsArray.length === 0) {
			getAllCompanyPositionsAsArray().pipe(take(1)).subscribe({});
		}

		if (components.filter(co => co?.module_id === item.id && co?.component_fields?.length == 0).length > 0 || components.filter(co => co?.module_id === item.id).length === 0) {
			setLoading(true);
			const module = allModules.find(m => m.id === item.id);

			setSelected(module.name);
			lastUpdates.componentsByModule[module.name] = 0;

			if (module?.options?.positions?.length > 0) {
				getUsersByPositions(module?.options?.positions)
					.pipe(take(1))
					.subscribe({});
			}
			getAlComponentsByModuleId(module, APPEND_TO_DATA, page_default, 1)
				.pipe(take(1))
				.subscribe({
					next: data => {
						const components_data = data?.data ? data?.data : data;

						store.dispatch(setAllComponents(R.sortBy(R.prop('created_date'), [...components.filter(c => c.module_id !== module.id), ...components_data]), CLEAR_TO_SET_DATA, blueprints.map(bp => bp.fields).flat(1)));

						lastUpdates.componentsByModule[module.name] = now();
						module.lastUpdate = now();
						module.count = data?.total ?? 0;
						
						if (module?.options?.supporting?.length > 0) {
							module?.options?.supporting?.filter((support, index) => {
								const smodule = allModules.find(m => m.id === support);
								setSelected(smodule?.name ?? module.name);

								getAlComponentsByModuleId({ id: support, name: `${allModules?.find(mod => mod.id === support)?.name}${index}` ?? `${module?.name}${index}` }, APPEND_TO_DATA, app_max, 1)
									.pipe(take(1))
									.subscribe({
										next: (data) => {
											if (smodule){
												smodule.count = data?.total ?? 0;
											}
											if (module?.options?.supporting?.length == index + 1) {
												setLoading(false);
												store.dispatch(setShowMenu(false));


												if (module.options?.single) {
													const cmp = localStorage.getItem(module.id);
													goToTransaction(module.procedure.id, cmp ? JSON.parse(cmp) : components.find(co => co.module_id === module.id));
												} else {
													navigate('/begin-module');
												}

											}

										},
										error: () => setLoading(false)
									})
							});

						} else {
							setLoading(false);
							store.dispatch(setShowMenu(false));

							if (module.options?.single) {
								const component = localStorage.getItem(module.id);
								goToTransaction(module.procedure.id, component ? JSON.parse(component) : components.find(co => co.module_id === module.id));

							} else {
								navigate('/begin-module');
							}
						}
					},
					complete: () => { },
					error: () => setLoading(false),
				});

		} else {

			store.dispatch(setShowMenu(false));

			if (item.options?.single) {
				const component = localStorage.getItem(item.id);

				goToTransaction(item.procedure.id, component ? JSON.parse(component) : components.find(co => co.module_id === item.id))

			} else
				navigate('/begin-module');
		}
	}

	return (
		<div className={inner ? '' : cls.modulesMenu} style={{ backgroundColor: isMobile ? 'white' : inner ? 'transparent' : companyData?.company_standards?.themeL ? companyData?.company_standards?.themeL : '' }}>
			<div className={cls.listContainerFixed} style={{ flexDirection: isMobile ? 'column' : '', padding: isMobile ? '' : '0 2% 0 2%', overflow: 'auto' }}>
				{!fullPage && R.sortWith([R.ascend(R.prop('name'))])(allModules.filter(mod => ((mod?.options?.hide === false || (mod.options.hide === true && user.is_admin === true) || mod?.options?.hide === undefined)))).map((item, index) => {
					return (
						<Button
							clear
							title={item.name}
							titleStyle={{ fontFamily: inner ? 'var(--app-text-open-sans)' : 'var(--app-text-main-font)', fontSize: inner ? '18px' : '14px', color: inner ? 'white' : '', letterSpacing: inner ? '-1px' : '' }}
							className={inner ? cls.dashboardButton : cls.listButton}
							style={{ backgroundColor: isMobile ? 'white' : inner ? 'transparent' : companyData?.company_standards?.themeL ? companyData?.company_standards?.themeL : '#F6F4EC', margin: '12px 0px 12px 0px', width: inner ?'220px':'' }}
							key={index}
							clicked={() => {
								gotoModule(item);
							}}
						>
						</Button>
					);
				})}
				{allModules.filter(mod => ((mod?.options?.hide === false || (mod.options.hide === true && user.is_admin === true) || mod?.options?.hide === undefined))).length > 13 && <Button
					light={fullPage ? true: false}
					clear={fullPage ? false: true}
					title={fullPage ? 'Hide' : 'MORE...'}
					titleStyle={{ fontFamily: inner ? 'var(--app-text-open-sans)' : 'var(--app-text-main-font)', fontSize: inner ? '18px' : '14px', color: inner ? 'white' : '', letterSpacing: inner ? '-1px' : '' }}
					className={inner ? cls.dashboardButton : cls.listButton}
					style={{ backgroundColor: isMobile ? 'white' : inner ? 'transparent' : companyData?.company_standards?.themeL ? companyData?.company_standards?.themeL : '#F6F4EC', margin: '12px 0px 12px 0px' }}
					clicked={() => {
						setFullPage(!fullPage);
					}}
				/>}
				{loading && <Button clear loading={loading} style={{ backgroundColor: 'transparent' }} message={`Fetching ${selected}... `} />}
			</div>

			{fullPage && <div className={cls.listContainerAll} style={{ flexDirection: isMobile ? 'column' : '', padding: isMobile ? '' : '0 2% 0 2%', overflow: 'auto' }}>
				{R.sortWith([R.ascend(R.prop('name'))])(allModules.filter(mod => ((mod?.options?.hide === false || (mod.options.hide === true && user.is_admin === true) || mod?.options?.hide === undefined)))).map((item, index) => {
					return (

						<div key={index} style={{ padding: '10px' }}>
							<UserCard
								noPhoto={true}
								height={'20vh'}
								firstName={item?.name}
								email={item?.procedure.description?.slice(0, 100)}
								cardClicked={() => {
									gotoModule(item);
								}}
							/>
						</div>


					);
				})}
			</div>}
		</div>
	);
}




const mapStateToProps = store => {
	const { allModules, lastUpdates, components, blueprints, companyPositionsArray, companyProcesses, companyData } = store.flowReducer;
	const { user } = store.sessionReducer;
	return { allModules, user, lastUpdates, components, blueprints, companyPositionsArray, companyProcesses, companyData };
};

export default connect(mapStateToProps)(ModulesMenu);
