import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import { Observable } from 'rxjs';
import qs from 'qs';
import { setLastUpdate, setProducts } from '../reduxStore/actions/flowActions';
import moment, { now } from 'moment';
const R = require('ramda');

export const createProduct = (name, product_code) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = new FormData();
	data.append('name', name);
	data.append('product_code', product_code);
	const config = {
		...URLS.products.createNewProduct(last_access_token),
		data: data,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 201 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const editProduct = (name, id) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const data = qs.stringify({
		name: name,
	});

	const config = {
		...URLS.products.updateProductById(last_access_token, id),
		data: data,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200 && response?.data) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
export const getProducts = (forced = false) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const { lastUpdates, axiosAbort } = store.getState().flowReducer;

	const config = {
		...URLS.products.getAllProducts(last_access_token),
		handlerEnabled: true,
		signal: axiosAbort.signal
	};

	//console.log('moment diff products', moment(now()).diff(moment(lastUpdates.products), 'minutes'), products.length);

	return new Observable(subscriber => {
		if (forced || moment(now()).diff(moment(lastUpdates.products), 'minutes') > 10) {
			axiosInstance(config)
				.then(response => {
					if (response?.status == 200 && response?.data && response?.data?.data) {
						store.dispatch(setLastUpdate({ ...lastUpdates, products: now() }));
						store.dispatch(setProducts(response.data.data.length > 0 ? R.sortBy(R.prop('name'), response.data.data) : []));
						subscriber.next(response?.data?.data);
						subscriber.complete();
					}
				})
				.catch(error => {
					subscriber.error(error);
				});
		} else {
			subscriber.complete();
		}
	});
};
export const deleteProduct = id => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;
	const config = {
		...URLS.products.deleteProductById(last_access_token, id),
		handlerEnabled: true,
	};
	return new Observable(subscriber =>
		axiosInstance(config)
			.then(response => {
				if (response?.status === 200) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch(error => {
				subscriber.error(error);
			})
	);
};
