import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../../services/auth.service';
import { ModalR } from '../index';
import Button from '../UI/Button/Button';
import cls from './LogoutModal.module.scss';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { restartAxiosAbort } from '../../../reduxStore/actions/flowActions';


const LogoutModal = props => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { openModal, setOpenModal, slug } = props;

	const { axiosAbort } = useSelector(store => store.flowReducer);

	const [loading, setLoading] = useState(false);
	const userLogoutHandler = () => {
		setLoading(true);
		userLogout(false).subscribe({
			next: () => slug ? navigate(`/pages/home/${slug}`) : navigate('/login'),
			error: () => setLoading(false),
		});
	};


	useEffect(() => {
		axiosAbort.abort();
		setTimeout(() => dispatch(restartAxiosAbort()), 1000);
	}, []);

	const triggerCloseModal = () => setOpenModal(false);
	return (
		<>
			<ModalR isOpen={openModal} onClose={triggerCloseModal} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ border: '5px black', width: isMobile ? '92%' : '20%', minWidth: '300px', backgroundColor: loading ? 'transparent' : '' }}>
				<div style={{ display: loading ? 'none' : '' }}>
					<p className={cls.logoutText}>{'Are you sure you want to log out?'}</p>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button title={'Logout'} clicked={() => userLogoutHandler()} loading={loading} />
						<Button title={'No, return back'} light clicked={triggerCloseModal} titleStyle={{ fontWeight: 'bold', ccolor: 'gray' }} />
					</div>
				</div>
			</ModalR>
		</>
	);
};

export default LogoutModal;
