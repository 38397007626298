import axios from 'axios';
import { sessionService } from 'redux-react-session';
import { resetAuthReducer, setAuthenticatedUser, setSessionSettings } from '../reduxStore/actions/authActions';
import { setAllModules, setCompanyData, setCompanyOrgChartData, setCompanyProcesses, setLastUpdate, setMessageBar, setOnBoardingComplete } from '../reduxStore/actions/flowActions';
import { actions_ErrorsModalClose, actions_ErrorsModalOpen } from '../reduxStore/actions/modalsActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import moment, { now } from 'moment';


import * as R from 'ramda';
import { APPEND_TO_DATA } from '../shared/utility';

const objectHas = (config, prop) => Object.prototype.hasOwnProperty.call(config, prop) == true;
const dispatch = fn => store.dispatch(fn);

export const axiosInstance = axios.create({ updateProfileAtSucces: false });
const isHandlerEnabled = (config = {}) => {
	return !(objectHas(config, 'handlerEnabled') && !config.handlerEnabled);
};
const getAuthenticatedUser = (config = {}) => {
	return objectHas(config, 'retrieveAuthenticatedUser') && config?.retrieveAuthenticatedUser;
};

const isModalOutputEnabled = (config = {}) => {
	return objectHas(config, 'withModalOutput') && config?.withModalOutput;
};
const forwardAllErrors = (config = {}) => {
	return objectHas(config, 'forwardAllErrors') && config?.forwardAllErrors;
};
const refreshOrgChart = (config = {}) => {
	return objectHas(config, 'refreshOrgChart') && config?.refreshOrgChart;
};
const noRetryWithToken = (config = {}) => {
	return objectHas(config, 'noTokenRetrival') && config?.noTokenRetrival;
};

const requestHandler = requestConfig => {
	if (isHandlerEnabled(requestConfig)) {
		return requestConfig;
	}
	return requestConfig;
};

axiosInstance.interceptors.request.use(request => requestHandler(request));

axiosInstance.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
const successHandler = response => {
	if (isHandlerEnabled(response.config)) {
		if ([200].indexOf(response.status) > -1) {
			if (getAuthenticatedUser(response?.config)) {
				const {
					sessionSettings: { last_access_token },
				} = store.getState().authReducer;

				if (response?.data?.access_token && response?.data?.expires_in && response?.data?.refresh_token && response?.config?.rememberMe) {
					const sessionSettings = {
						rememberMe: response?.config?.rememberMe,
						expirationDate: moment()
							.add(+response.data.expires_in * 1000, 'seconds')
							.format('YYYY-MM-DD hh:mm:ss:ssss'),
						last_access_token: response?.data?.access_token,
						last_expires_in: response?.data?.expires_in,
						last_refresh_token: response?.data?.refresh_token,
					};
					dispatch(setSessionSettings(sessionSettings));
					const currentSession = { ...store.getState.sessionReducer, ...sessionSettings };
					sessionService.saveSession(currentSession).then(() => {});
				}
				const token = response?.data?.access_token ? response?.data?.access_token : last_access_token;
				const getAuthenticatedUserConfig = { ...URLS.users.getAuthenticatedUser(token), handlerEnabled: true };
				const { lastUpdates } = store.getState().flowReducer;

				return axiosInstance(getAuthenticatedUserConfig)
					.then(res => {
						if (res?.status === 200 && res?.data) {
							sessionService.saveUser({ ...res?.data?.data });
							dispatch(setAuthenticatedUser({ ...res?.data?.data }));
							
							const getCompanyDetailsConfig = {
								...URLS.company.getCompanyDetails(token),
								handlerEnabled: true,
							};
							return axiosInstance(getCompanyDetailsConfig)
								.then(re => {
									if (re?.status === 200 && re?.data?.data) {
										
										if (re?.data?.modules && re?.data?.data?.status !== 'free'){
											dispatch(setAllModules(re?.data?.modules));
											dispatch(setLastUpdate({ ...lastUpdates, modules: now() }));
										}
										if (re?.data?.procedures  && re?.data?.data?.status !== 'free'){
											const ascendingProcesses = re.data?.procedures.length > 0 ? R.sortBy(R.prop('name'), re?.data?.procedures) : [];
											dispatch(setCompanyProcesses(ascendingProcesses, APPEND_TO_DATA));
											dispatch(setLastUpdate({ ...lastUpdates, procedures: now() }));		
										}
										
										
										dispatch(setCompanyData(re?.data?.data));
										if (re?.data?.data?.onboarding_status === true) {
											dispatch(setOnBoardingComplete(true));
										}
										return {
											...response,
											data: {
												...response.data,
												is_admin: store.getState().authReducer.authenticatedUser?.is_admin,
												onboarding_status: re?.data?.data?.onboarding_status,
											},
										};
									} else {
										return response;
									}
								})
								.catch(auto_inner_2_error => {
									return Promise.reject(auto_inner_2_error);
								});
						}
					})
					.catch(auto_inner_1_error => {
						return Promise.reject(auto_inner_1_error);
					});
			}
			if (refreshOrgChart(response.config)) {
				const {
					sessionSettings: { last_access_token },
				} = store.getState().authReducer;
				const token = response?.data?.access_token ? response?.data?.access_token : last_access_token;
				const config = {
					...URLS.positions.getPositionsOrgChart(token),
					handlerEnabled: true,
				};
				axiosInstance(config)
					.then(response => {
						if (response?.status == 200 && response?.data && response?.data?.data) {
							store.dispatch(setCompanyOrgChartData(response?.data?.data));
							return response;
						}
					})
					.catch(error => {
						return Promise.reject(error);
					});
			}
		}
	}
	return response;
};
const errorHandler = error => {
	if (isHandlerEnabled(error.config)) {
		/*  */
	}
	const originalRequest = error.config;

	if (noRetryWithToken(error.config)) {
		return Promise.reject(error.response?.data);
	}

	const isNotLogin = error?.config?.url?.indexOf('scbprocessmakerdevapi.adpgxsoftware.com/api/register') === -1 && error?.config?.url?.indexOf('scbprocessmakerdevapi.adpgxsoftware.com/api/auth/login') === -1;
	if (error?.response?.status === 401 && !originalRequest._retry && isNotLogin) {
		const {
			sessionSettings: { last_refresh_token },
		} = store.getState().authReducer;

		const { authenticated } = store.getState().sessionReducer;
		const { axiosAbort } = store.getState().flowReducer;
		if (!authenticated) {
			return { error: 'Not authenticated' };
		}

		originalRequest._retry = true;
		const data = new FormData();
		data.append('refresh_token', last_refresh_token);
		const config_REF_TOK = {
			...URLS.auth.refreshToken(last_refresh_token),
			data: data,
			signal: axiosAbort.signal
		};
		return axios(config_REF_TOK)
			.then(res => {
				if (res?.data) {
					const sessionSettings = {
						rememberMe: store.getState()?.authReducer?.sessionSettings?.rememberMe,
						expirationDate: moment()
							.add(+res.data.expires_in * 1000, 'seconds')
							.format('YYYY-MM-DD hh:mm:ss:ssss'),
						last_access_token: res?.data?.access_token,
						last_expires_in: res?.data?.expires_in,
						last_refresh_token: res?.data?.refresh_token,
					};
					dispatch(setSessionSettings(sessionSettings));
					const currentSession = { ...store.getState.sessionReducer, ...sessionSettings };
					sessionService
						.saveSession(currentSession)
						.then(() => {
							const authenticatedUser = store.getState().authReducer.authenticatedUser;
							sessionService
								.saveUser(authenticatedUser)
								.then(() => {
									// 2) Change Authorization header
									originalRequest.headers = {
										...originalRequest.headers,
										Authorization: 'Bearer ' + res.data.access_token,
									};

									// 3) return originalRequest object with Axios.
									return axios(originalRequest);
								})
								.catch(err => {
									console.log('  sessionService.saveUser error ', err);
								});
						})
						.catch(_err => {
							axios(originalRequest);
						});
				}
			})
			.catch(error => {
				sessionService.deleteSession().then(() => sessionService.deleteUser());
				dispatch(resetAuthReducer());
				let err = { status: error?.response?.status, data: error?.response?.data || error };
				let fwdErr = '';
				if (error.response?.data?.message) {
					const c = error.response?.data?.message;
					fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
					err = { status: error?.response?.status, data: fwdErr };
				}
				return Promise.reject(err);
			});
	}

	let err = { status: error?.response?.status, data: error?.response?.data };
	let fwdErr = '';
	switch (error?.response?.status && error?.response?.status) {
		case 401:
		case 500:
			if (error.response?.data?.message) {
				const c = error.response?.data?.message;
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n ' + c : fwdErr;
			}
			err = { status: error?.response?.status, data: fwdErr };
			break;
		case 403:
		case 422:
			if (error.response?.data?.errors?.position_id?.length > 0) {
				const c = error.response?.data?.errors?.position_id[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}
			if (error.response?.data?.errors?.founding_date?.length > 0) {
				const c = error.response?.data?.errors?.founding_date[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}
			if (error.response?.data?.errors?.misc_info?.length > 0) {
				const c = error.response?.data?.errors?.misc_info[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}

			if (error.response?.data?.errors?.email?.length > 0) {
				const c = error.response?.data?.errors?.email[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}
			if (error.response?.data?.errors?.password?.length > 0) {
				fwdErr = error.response?.data?.errors;
			}
			if (error.response?.data?.errors?.invitation_id?.length > 0) {
				const c = error.response?.data?.errors?.invitation_id[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}
			if (error.response?.data?.errors?.name?.length > 0) {
				const c = error.response?.data?.errors?.name[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}
			if (error.response?.data?.errors?.old_password?.length > 0) {
				const c = error.response?.data?.errors?.old_password[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}
			if (error.response?.data?.errors?.logo?.length > 0) {
				const c = error.response?.data?.errors?.logo[0];
				fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
			}
			if (error.response?.data?.errors?.photo?.length > 0) {
				const c = error.response?.data?.errors?.photo[0];
				if (Array.isArray(fwdErr)) {
					fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
				}
			}

			if (error.response?.data?.message || error.response?.data?.data?.message) {
				const c = error.response?.data?.message ?? error.response?.message ?? error.response?.data?.data?.message;
				if (isNotLogin && Array.isArray(fwdErr)) {
					fwdErr = c !== fwdErr?.split('\n')[fwdErr?.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
				}
			}

			if (error.response?.data?.message && error.response?.data.errors && forwardAllErrors(error.config) == true) {
				fwdErr = error.response?.data;
				err = { status: error?.response?.status, data: fwdErr };
			}
			if (forwardAllErrors(error.config) == false) {
				err = { status: error?.response?.status, data: fwdErr?.trim() };
			}

			break;
		case 404:
			if (error.response?.data) {
				const c = error.response?.data?.message ?? error.response?.message ?? error.response.data.data.message;
				if (isNotLogin) {
					fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
					err = { status: error?.response?.status, data: fwdErr };
				}
			}
			if (error.response?.data?.message && error.response?.data.errors && forwardAllErrors(error.config) == true) {
				fwdErr = error.response?.data;
				err = { status: error?.response?.status, data: fwdErr };
			}
			if (forwardAllErrors(error.config) == false) {
				err = { status: error?.response?.status, data: fwdErr?.trim() };
			}
			break;
		default: {
			err = { status: error?.response?.status, data: error?.toString() };
		}
	}

	if (forwardAllErrors(error.config) == true) {
		err = { status: error?.response?.status, data: error?.response?.data };
	}
	if (!isNotLogin && typeof err?.data === 'string' && err?.data?.indexOf('\n') == -1) {
		err = { status: error?.response?.status, data: error?.response?.data?.message ?? error.message };
	}
	if (isModalOutputEnabled(error.config) === true) {
		const options = {
			headingCentered: true,
			heading: 'ERROR' + error?.response?.status + ' on ' + error?.config?.url,
			subheading: JSON.stringify(error.response?.data),
			subheadingCentered: true,
			onClose: () => {},
			firstBtnText: 'close',
			onFirstButtonClick: () => {
				store.dispatch(actions_ErrorsModalClose());
			},
			secondBtnText: 'retry',
			onSecondButtonClick: () => {},
		};
		store.dispatch(actions_ErrorsModalOpen({ isOpen: true, options: options }));
	}

	return Promise.reject(err);
};
