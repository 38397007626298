import React from 'react';
import cls from './UserCard.module.scss';
import { uncachedUrl } from '../../../shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
const R = require('ramda');

const UserCard = props => {
	const { ratings, noPhoto, cardClicked = () => { }, firstName = '', lastName = '', position, children, email, photo, height, width, simple, title = '' } = props;

	return simple ? (
		<div className={cls.simple} onClick={() => cardClicked()} style={{ height: height ? height : '', width: width ? width : '', boxShadow: simple ? '0 0 0 0 rgba(0, 0, 0, 0.1)' : '' }}>
			{!noPhoto && photo?.length > 0 && <img alt="lg" src={uncachedUrl(photo)} className={cls.simpleImg} />}

			<div style={{ display: '', textAlign: 'left' }}>
				{ratings?.length > 0 ? (
					<div>
						<div className={cls.simpleNames}>
							{`${firstName} ${lastName}`}
						</div>
						<div className={cls.simpleNames}>
							{Array(Math.round(R.converge(R.divide, [R.sum, R.length])(ratings)))
								.fill(0)
								.map((obj, index) => {
									return <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'var(--app-color-blue)' }} />;
								})}
							{Array(5 - Math.round(R.converge(R.divide, [R.sum, R.length])(ratings)))
								.fill(0)
								.map((obj, index) => {
									return <FontAwesomeIcon key={index} icon={faStar} style={{ color: 'lightgray' }} />;
								})}
						</div>
					</div>
				) : (
					<div style={{ display: 'flex' }}>
						<div className={cls.simpleNames}>{firstName}</div>
						<div className={cls.simpleNames} style={{ marginLeft: '5px' }}>{lastName}</div>
					</div>
				)}
				<div className={cls.simpleEmail}>{`${email}`}</div>
				<div className={cls.simpleEmail}>{`${position}`}</div>
			</div>


		</div>
	) : (
		<div className={noPhoto ? cls.userCardNoPhoto : cls.userCard} onClick={() => cardClicked()} style={{ height: height ? height : '', width: width ? width : '', boxShadow: simple ? '0 0 0 0 rgba(0, 0, 0, 0.1)' : '' }}>
			<div className={cls.userCardContainer}>
				{!noPhoto && (
					<div className={cls.userCardContainerTop}>
						<div className={cls.userCardInnerContainer}>{photo?.length > 0 && <img alt="lg" src={uncachedUrl(photo)} className={cls.avatarUrlImg} />}</div>
					</div>
				)}
				<div className={cls.userCardBottomContainer}>
					<div style={{ fontSize: isMobile ? '22px' : '' }} className={cls.firstName}>{firstName}</div>
					<div style={{ fontSize: isMobile ? '22px' : '' }} className={cls.fullName}>{lastName}</div>
					<p className={cls.email}>{email}</p>
					<p className={cls.position}>{position}</p>

					{children}
				</div>
			</div>
		</div>
	);
};

export default UserCard;
